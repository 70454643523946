export const environment = {
  production: true,
  stage: false,
  apiUrl: 'https://api.runple.com',
  javaApiUrl: 'https://java-back-it-work.runple.app',
  phpApiUrl: 'https://api-it-work.runple.app',
  javaApiVersion: 'hq/v1',
  phpApiVersion: 'api/v1',
  javaAccountingApiVersion: 'accounting/v1',
};
