<header id="header" class="header-container">
  <div class="header-wrapper">
    <div class="header-logo">
      <a [routerLink]="localeService.rootRoute" class="link md" aria-label="start page">
        <svg class="runple-logo" width="150" height="56" viewBox="0 0 150 56" fill="none">
          <g>
            <path class="main" fill-rule="evenodd" clip-rule="evenodd" d="M134.838 34.009C136.709 35.3031 139.156 35.5154 141.224 34.5627C142.331 34.0534 143.248 33.2623 143.902 32.2594L148.358 33.8645C147.235 36.0014 145.424 37.72 143.202 38.743C137.657 41.2955 131.045 38.9161 128.463 33.4373C127.217 30.7957 127.083 27.8311 128.085 25.0903C129.095 22.327 131.136 20.1217 133.832 18.8804C135.343 18.1844 136.934 17.8551 138.501 17.8551C142.682 17.8551 146.693 20.201 148.571 24.1864C148.904 24.8907 149.159 25.6259 149.333 26.3769L134.007 33.4342L134.838 34.009ZM135.81 23.0607C133.74 24.0136 132.329 26.0043 132.127 28.2562L132.038 29.2492L143.027 24.1893L142.196 23.6144C140.325 22.3212 137.878 22.1086 135.81 23.0607Z" fill="white"/>
            <path class="main" d="M118.493 38.9565H123.362V0L118.493 2.55179V38.9565Z" fill="white"/>
            <path class="main" d="M26.2431 22.1408C32.3764 22.1408 37.3665 17.1745 37.3665 11.0704C37.3665 4.96626 32.3764 0 26.2431 0H2.67525L0 5.32499H26.2431C29.4263 5.32499 32.016 7.90229 32.016 11.0704C32.016 14.2385 29.4263 16.8158 26.2431 16.8158H21.9252H18.6397H17.7178L15.0425 22.1408H15.0493L15.049 22.141L30.2483 38.9565H37.1969L22.4127 22.1408H26.2431Z" fill="white"/>
            <path class="darked" d="M15.0416 22.1366H22.4102L27.4375 27.8536L15.0416 22.1366Z" fill="#9CA4BE"/>
            <path class="main" d="M76.6957 17.0435C70.6541 17.0435 65.7391 21.9585 65.7391 28V38.9565H70.796V28C70.796 24.7468 73.4424 22.1003 76.6957 22.1003C79.9486 22.1003 82.5953 24.7468 82.5953 28V38.9565H87.6522V28C87.6522 21.9585 82.7372 17.0435 76.6957 17.0435Z" fill="white"/>
            <path class="main" d="M56.6243 28C56.6243 31.2533 53.9779 33.8997 50.7246 33.8997C47.4714 33.8997 44.825 31.2533 44.825 28V17.0435H39.7681V28C39.7681 34.0413 44.6831 38.9565 50.7246 38.9565C56.7662 38.9565 61.6812 34.0413 61.6812 28V17.0435H56.6243V28Z" fill="white"/>
            <path class="main" fill-rule="evenodd" clip-rule="evenodd" d="M91.7101 28.1017C91.7107 22.0042 96.6257 17.0435 102.667 17.0435C108.708 17.0435 113.623 22.0045 113.623 28.1025C113.623 34.2003 108.708 39.1616 102.667 39.1616C100.743 39.1616 98.8476 38.6481 97.1859 37.6768L96.767 37.4333V38.003V53.4479L91.7101 56V28.1017ZM96.767 28.1025C96.767 31.3859 99.4137 34.0574 102.667 34.0574C105.92 34.0574 108.566 31.3859 108.566 28.1025C108.566 24.8188 105.92 22.1477 102.667 22.1477C99.4137 22.1477 96.767 24.8188 96.767 28.1025Z" fill="white"/>
          </g>
        </svg>
      </a>
<!--// for iphone-->
      <a [routerLink]="localeService.rootRoute" class="link sm" aria-label="start page" (click)="closeNav(solutionGroup, featuresGroup)">
        <svg width="108" height="40" viewBox="0 0 108 40" fill="none">
          <g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M96.313 24.2921C97.6497 25.2165 99.3977 25.3681 100.874 24.6877C101.665 24.3239 102.32 23.7588 102.787 23.0425L105.97 24.1889C105.168 25.7153 103.875 26.9429 102.288 27.6736C98.3266 29.4968 93.6039 27.7972 91.7593 23.8838C90.8697 21.997 90.7738 19.8794 91.4896 17.9216C92.2112 15.9478 93.6688 14.3727 95.5943 13.486C96.6741 12.9889 97.8098 12.7536 98.9293 12.7536C101.916 12.7536 104.781 14.4293 106.122 17.276C106.36 17.7791 106.542 18.3042 106.667 18.8406L95.7191 23.8815L96.313 24.2921ZM97.0075 16.4719C95.529 17.1526 94.521 18.5745 94.3767 20.183L94.313 20.8923L102.163 17.278L101.569 16.8675C100.232 15.9437 98.4843 15.7919 97.0075 16.4719Z" fill="white"/>
            <path d="M84.6377 27.8261H88.116V0L84.6377 1.82271V27.8261Z" fill="white"/>
            <path d="M18.745 15.8148C23.126 15.8148 26.6903 12.2675 26.6903 7.90741C26.6903 3.54733 23.126 0 18.745 0H1.91089L0 3.80357H18.745C21.0188 3.80357 22.8686 5.64449 22.8686 7.90741C22.8686 10.1703 21.0188 12.0113 18.745 12.0113H15.6609H13.3141H12.6555L10.7446 15.8148H10.7495L10.7493 15.815L21.606 27.8261H26.5692L16.0091 15.8148H18.745Z" fill="white"/>
            <path d="M10.7441 15.8119H16.0074L19.5983 19.8955L10.7441 15.8119Z" fill="#9CA4BE"/>
            <path d="M54.7826 12.1739C50.4672 12.1739 46.9565 15.6846 46.9565 20V27.8261H50.5686V20C50.5686 17.6763 52.4589 15.786 54.7826 15.786C57.1062 15.786 58.9967 17.6763 58.9967 20V27.8261H62.6087V20C62.6087 15.6846 59.098 12.1739 54.7826 12.1739Z" fill="white"/>
            <path d="M40.4459 20C40.4459 22.3237 38.5556 24.214 36.2318 24.214C33.9081 24.214 32.0178 22.3237 32.0178 20V12.1739H28.4058V20C28.4058 24.3152 31.9165 27.8261 36.2318 27.8261C40.5472 27.8261 44.0579 24.3152 44.0579 20V12.1739H40.4459V20Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M65.5073 20.0726C65.5077 15.7173 69.0184 12.1739 73.3334 12.1739C77.6488 12.1739 81.1595 15.7175 81.1595 20.0732C81.1595 24.4288 77.6488 27.9726 73.3334 27.9726C71.9592 27.9726 70.6055 27.6058 69.4186 26.912L69.1194 26.738V27.145V38.1771L65.5073 40V20.0726ZM69.1194 20.0732C69.1194 22.4185 71.0099 24.3267 73.3334 24.3267C75.657 24.3267 77.5475 22.4185 77.5475 20.0732C77.5475 17.7277 75.657 15.8198 73.3334 15.8198C71.0099 15.8198 69.1194 17.7277 69.1194 20.0732Z" fill="white"/>
          </g>
        </svg>
      </a>
    </div>

    <ul class="header-navigation">
      <li class="header-navigation-item pr-0">
        {{'MAIN.SOLUTIONS' | translate}}
        <span class="icon">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="white"/>
          </svg>
        </span>

        <div class="sub-menu-container solutions">

          <div class="sub-menu-wrapper p-16">
            <div class="navigate-group pt-0">
              <ul class="nav-list">
                <li *ngFor="let item of solutionsList; trackBy: trackByFn" class="nav-item">
                  <a [routerLink]="item?.routerLink" class="link">
                    <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" alt="">
                    <span class="title">{{item.title | translate}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </li>
      <li class="header-navigation-item pr-0">
        {{'MAIN.FEATURES' | translate}}
        <span class="icon">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="white"/>
          </svg>
        </span>

        <div class="sub-menu-container">

          <div class="sub-menu-wrapper">
            <div *ngFor="let group of featuresList; trackBy: trackByFn" class="navigate-group">
                <span *ngIf="!!group?.title" class="navigate-group-title font-700">{{group.title | translate}}</span>
                <ul class="nav-list">
                  <li *ngFor="let item of group.navList; trackBy: trackByFn" class="nav-item">
                    <a [routerLink]="item?.routerLink" class="link">
                      <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" [alt]="item?.title">
                      <span class="title">{{item.title | translate}}</span>
                    </a>
                  </li>
                </ul>
              </div>
          </div>

        </div>
      </li>
      <li class="header-navigation-item">
        <a [routerLink]="localeService.getLink(routesEnum.PRICING)" class="link">{{'MAIN.PRICING' | translate}}</a>
      </li>
<!--      <li class="header-navigation-item">-->
<!--        <a [routerLink]="'about'" class="link">About Runple</a>-->
<!--      </li>-->
    </ul>

    <div class="header-actions">
      <button type="button" class="btn btn-md btn-black mobile-none" aria-label="Log in" (click)="goToSignIn()">
        <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.7019 4.7019C6.3114 4.09241 7.13805 3.75 8 3.75H17C17.862 3.75 18.6886 4.09241 19.2981 4.7019C19.9076 5.3114 20.25 6.13805 20.25 7V17C20.25 17.862 19.9076 18.6886 19.2981 19.2981C18.6886 19.9076 17.862 20.25 17 20.25H8C7.13805 20.25 6.3114 19.9076 5.7019 19.2981C5.09241 18.6886 4.75 17.862 4.75 17V16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V17C3.25 18.2598 3.75044 19.468 4.64124 20.3588C5.53204 21.2496 6.74022 21.75 8 21.75H17C18.2598 21.75 19.468 21.2496 20.3588 20.3588C21.2496 19.468 21.75 18.2598 21.75 17V7C21.75 5.74022 21.2496 4.53204 20.3588 3.64124C19.468 2.75044 18.2598 2.25 17 2.25H8C6.74022 2.25 5.53204 2.75044 4.64124 3.64124C3.75044 4.53204 3.25 5.74022 3.25 7V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V7C4.75 6.13805 5.09241 5.3114 5.7019 4.7019Z" fill="white"/>
          <path d="M10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967L14.5303 11.4697C14.6022 11.5416 14.6565 11.6245 14.6931 11.7129C14.7298 11.8013 14.75 11.8983 14.75 12C14.75 12.1919 14.6768 12.3839 14.5303 12.5303L11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303C10.1768 15.2374 10.1768 14.7626 10.4697 14.4697L12.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H12.1893L10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967Z" fill="white"/>
        </svg>
        {{'MAIN.SIGN_IN' | translate}}
      </button>

      <button *ngIf="!(hideStartToFreeAction$ | async)" type="button" class="btn btn-md" aria-label="Start for free" (click)="goToSignUpPage(); closeNav(solutionGroup, featuresGroup)">
        <img class="desktop" height="24" width="24" ngSrc="assets/img/cursor.svg" alt="">
        <img class="tablet" height="24" width="24" ngSrc="assets/img/finger-tap.svg" alt="">
        {{'MAIN.START_FOR_FREE' | translate}}
      </button>

      <button type="button" class="btn btn-md btn-icon btn-black menu-toggle" aria-label="Navigation toggle"
              (click)="navToggle(); solutionGroup.setAttribute('open', ''); featuresGroup.removeAttribute('open')"
      >
        <img *ngIf="!isOpenNav" height="24" width="24" ngSrc="assets/img/menu.svg" alt="">
        <img *ngIf="isOpenNav" height="24" width="24" ngSrc="assets/img/close.svg" alt="">
      </button>
    </div>
  </div>

  <div class="mobile-navigation" [class.open]="isOpenNav">
    <div class="mobile-navigation-container">
      <div #solutionGroup class="mobile-navigation-group" open>
        <div class="title-container d-flex align-items-center" (click)="solutionGroup.toggleAttribute('open')">
          <span class="title font-700">{{'MAIN.SOLUTIONS' | translate}}</span>
          <span class="icon">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="toggle" fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="white"/>
          </svg>
        </span>
        </div>

        <div class="navigate-group-container">
          <div class="navigate-group pt-0">
            <ul class="nav-list">
              <li *ngFor="let item of solutionsList; trackBy: trackByFn" class="nav-item">
                <a [routerLink]="item?.routerLink" (click)="closeNav(solutionGroup, featuresGroup)" class="link">
                  <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" alt="">
                  <span class="title">{{item.title | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div #featuresGroup class="mobile-navigation-group">
        <div class="title-container d-flex align-items-center" (click)="featuresGroup.toggleAttribute('open')">
          <span class="title font-700">{{'MAIN.FEATURES' | translate}}</span>
          <span class="icon">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16987 13.9249C8.46276 14.2178 8.93763 14.2178 9.23053 13.9249L11.9931 11.1623L14.7557 13.9249C15.0485 14.2178 15.5234 14.2178 15.8163 13.9249C16.1092 13.632 16.1092 13.1571 15.8163 12.8642L12.5234 9.57131C12.2305 9.27841 11.7557 9.27841 11.4628 9.57131L8.16987 12.8642C7.87697 13.1571 7.87697 13.632 8.16987 13.9249Z" fill="white"/>
          </svg>
        </span>
        </div>

        <div class="navigate-group-container">
          <div *ngFor="let group of featuresList; trackBy: trackByFn" class="navigate-group">
            <span *ngIf="!!group?.title" class="navigate-group-title font-700">{{group.title | translate}}</span>
            <ul class="nav-list">
              <li *ngFor="let item of group.navList; trackBy: trackByFn" class="nav-item">
                <a [routerLink]="item?.routerLink" (click)="closeNav(solutionGroup, featuresGroup)" class="link">
                  <img height="32" width="32" [ngSrc]="'assets/img/app-icon/' + item?.icon" alt="">
                  <span class="title">{{item.title | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mobile-navigation-group">
        <a [routerLink]="localeService.getLink(routesEnum.PRICING)" (click)="closeNav(solutionGroup, featuresGroup)" class="link font-700">{{'MAIN.PRICING' | translate}}</a>
      </div>

<!--      <div class="mobile-navigation-group">-->
<!--        <a [routerLink]="'about'" (click)="closeNav(solutionGroup, featuresGroup)" class="link font-700">About Runple</a>-->
<!--      </div>-->

      <div class="action">
        <button type="button" class="btn btn-md btn-black" aria-label="Log in" (click)="goToSignIn(); closeNav(solutionGroup, featuresGroup)">
          <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.7019 4.7019C6.3114 4.09241 7.13805 3.75 8 3.75H17C17.862 3.75 18.6886 4.09241 19.2981 4.7019C19.9076 5.3114 20.25 6.13805 20.25 7V17C20.25 17.862 19.9076 18.6886 19.2981 19.2981C18.6886 19.9076 17.862 20.25 17 20.25H8C7.13805 20.25 6.3114 19.9076 5.7019 19.2981C5.09241 18.6886 4.75 17.862 4.75 17V16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V17C3.25 18.2598 3.75044 19.468 4.64124 20.3588C5.53204 21.2496 6.74022 21.75 8 21.75H17C18.2598 21.75 19.468 21.2496 20.3588 20.3588C21.2496 19.468 21.75 18.2598 21.75 17V7C21.75 5.74022 21.2496 4.53204 20.3588 3.64124C19.468 2.75044 18.2598 2.25 17 2.25H8C6.74022 2.25 5.53204 2.75044 4.64124 3.64124C3.75044 4.53204 3.25 5.74022 3.25 7V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V7C4.75 6.13805 5.09241 5.3114 5.7019 4.7019Z" fill="white"/>
            <path d="M10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967L14.5303 11.4697C14.6022 11.5416 14.6565 11.6245 14.6931 11.7129C14.7298 11.8013 14.75 11.8983 14.75 12C14.75 12.1919 14.6768 12.3839 14.5303 12.5303L11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303C10.1768 15.2374 10.1768 14.7626 10.4697 14.4697L12.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H12.1893L10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967Z" fill="white"/>
          </svg>
          {{'MAIN.SIGN_IN' | translate}}
        </button>
      </div>
    </div>

  </div>
</header>

<div class="header-overlay"
     [class.show]="isOpenNav"
     (click)="navToggle(); solutionGroup.removeAttribute('open'); featuresGroup.removeAttribute('open')"
></div>
