import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from '@ngx-translate/core';

import { RequestDemoModalComponent } from './request-demo-modal.component';
import { FormValidIconModule } from '../../ui-components/form-valid-icon/form-valid-icon.module';

@NgModule({
  declarations: [
    RequestDemoModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FormValidIconModule,
    TranslateModule
  ],
})
export class RequestDemoModalModule { }
