import { APP_INITIALIZER, Inject, Injector, NgModule, PLATFORM_ID, TransferState } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser, LOCATION_INITIALIZED, ViewportScroller } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { filter } from 'rxjs/operators';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule, HeaderModule } from './ui-components';
import { RequestDemoModule } from './ui-components/request-demo/request-demo.module';
import { ToTopButtonModule } from './ui-components/to-top-button/to-top-button.module';
import { ApiInterceptor } from './api.interceptor';
import { ApiService } from './pages/sign-up/services/api.service';
import { ContactUsModalModule } from './modals/contact-us-modal/contact-us-modal.module';
import { RequestDemoModalModule } from './modals/request-demo-modal/request-demo-modal.module';
import { TitleService } from "./services/title.service";
import { AppService } from "./app.service";
import { LocaleService } from "./locale/locale.service";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { translateBrowserLoaderFactory } from "./services/translate-browser.loader";
import { CookiesAgreementModule } from "./ui-components/cookies-agreement/cookies-agreement.module";
import { ContactSupportModalModule } from "./modals/contact-support-modal/contact-support-modal.module";
import { ToasterModule } from "./ui-components/toaster/toaster.module";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'en';
      translate.setDefaultLang('en');
      translate.use(langToSet).subscribe(() => {

      }, err => {

      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    TransferHttpCacheModule,
    MatDialogModule,
    RequestDemoModule,
    ToTopButtonModule,
    HttpClientModule,
    ContactUsModalModule,
    ContactSupportModalModule,
    RequestDemoModalModule,
    CookiesAgreementModule,
    ToasterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
  ],
  providers: [
    provideClientHydration(),
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    ApiService,
    TitleService,
    LocaleService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    activeRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    router.events
      // .pipe(filter((e): e is Scroll => e instanceof Scroll))
      // .subscribe((e) => {
      //   let scrollContainer: HTMLElement | null;
      //   const externalPage = activeRoute?.firstChild?.snapshot?.data?.['isExternalPage'];
      //
      //   if (isPlatformBrowser(this.platformId) && this.document && !externalPage) {
      //     scrollContainer = this.document.getElementById('main-container');
      //
      //     if (!e.position && !e.anchor) {
      //       scrollContainer?.scrollTo({top: 0})
      //     }
      //   }
      // });

  .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe((e) => {
        viewportScroller.setOffset([0, 128]);

        if (e.position) {
          // backward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition(e.position as [number, number]);
          }, 0);
        } else if (e.anchor) {
          // anchor navigation
          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor as string);
          }, 0);
        } else {
          // forward navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition([0, 0]);
          }, 0);
        }
      });
  }
}
