export function getBranchesList(
  pointOfSaleLink: string,
  ecommerceSoftwareLink: string,
  inventoryManagementLink: string,
  accountingSoftwareLink: string,
): any[] {
  return [
    {
      title: 'MAIN.POINT_OF_SALE',
      routerLink: pointOfSaleLink,
      img: 'retail-icon.png',
      id: 1
    },
    {
      title: 'MAIN.ECOMMERCE_SOFTWARE',
      routerLink: ecommerceSoftwareLink,
      img: 'e-commerce-icon.png',
      id: 2
    },
    {
      title: 'MAIN.INVENTORY_MANAGEMENT_SOFTWARE',
      routerLink: inventoryManagementLink,
      img: 'wholesale-icon.png',
      id: 3
    },
    // {
    //   title: 'Software for service providers',
    //   routerLink: '',
    //   img: 'services-icon.png',
    //   id: 4
    // },
    {
      title: 'MAIN.ACCOUNTING_SOFT_WARE',
      routerLink: accountingSoftwareLink,
      img: 'accounting-icon.png',
      id: 5
    },
  ];
}

export function getFeaturesList(
  retailLink: string,
  ecommerceLink: string,
  tradeLink: string,
  servicesLink: string,
  accountingLink: string,
  warehouseLink: string,
  analyticsLink: string,
  partnersLink: string,
  productsLink: string,
  teamLink: string,
): any[] {
 return [
   {
     title: 'MAIN.RETAIL',
     routerLink: retailLink,
     id: 1,
   },
   {
     title: 'MAIN.ECOMMERCE',
     routerLink: ecommerceLink,
     id: 2,
   },
   {
     title: 'MAIN.WHOLESALE',
     routerLink: tradeLink,
     id: 3,
   },
   {
     title: 'MAIN.SERVICE_PROVIDERS',
     routerLink: servicesLink,
     id: 4,
   },
   {
     title: 'MAIN.ACCOUNTING',
     routerLink: accountingLink,
     id: 5,
   },
   {
     title: 'MAIN.WAREHOUSE',
     routerLink: warehouseLink,
     id: 6,
   },
   {
     title: 'MAIN.BUSINESS_INTELLIGENCE',
     routerLink: analyticsLink,
     id: 7,
   },
   {
     title: 'MAIN.PARTNERS',
     routerLink: partnersLink,
     id: 8,
   },
   {
     title: 'MAIN.PRODUCTS',
     routerLink: productsLink,
     id: 9,
   },
   {
     title: 'FORM.HUMAN_RESOURCE',
     routerLink: teamLink,
     id: 10,
   },
 ];
}

export function getUsageScenariosList(
  accountingSeoLink: string,
  tradeSeoLink: string,
  erpSoftwareLink: string,
  ecommerceSeoLink: string,
  runpleFreeSeoLink: string,
  onlineStoreSeoLink: string
): any[] {
  return [
    {
      title: 'Warenwirtschaftssoftware',
      routerLink: tradeSeoLink,
      id: 1,
    },
    {
      title: 'ERP-Software',
      routerLink: erpSoftwareLink,
      id: 2,
    },
    {
      title: 'All-in-One-Software',
      routerLink: onlineStoreSeoLink,
      id: 3,
    },
    {
      title: 'E-Commerce-Software',
      routerLink: ecommerceSeoLink,
      id: 4,
    },
    {
      title: 'Buchhaltungssoftware',
      routerLink: accountingSeoLink,
      id: 5,
    },
    {
      title: 'Buchhaltungssoftware kostenlos',
      routerLink: runpleFreeSeoLink,
      id: 6,
    },
    // {
    //   title: 'ERP software',
    //   routerLink: servicesSeoLink,
    //   id: 7,
    // },
    // {
    //   title: 'Accounting software',
    //   routerLink: accountingSeoLink,
    //   id: 8,
    // },
    // {
    //   title: 'Runple free',
    //   routerLink: '',
    //   id: 9,
    // },
    // {
    //   title: 'Online store',
    //   routerLink: ecommerceSeoLink,
    //   id: 10,
    // },
    // {
    //   title: 'WaWi',
    //   routerLink: tradeSeoLink,
    //   id: 11,
    // },
    // {
    //   title: 'ERP software',
    //   routerLink: servicesSeoLink,
    //   id: 12,
    // },
    // {
    //   title: 'Accounting software',
    //   routerLink: accountingSeoLink,
    //   id: 13,
    // },
    // {
    //   title: 'Runple free',
    //   routerLink: '',
    //   id: 14,
    // },
    // {
    //   title: 'Online store',
    //   routerLink: ecommerceSeoLink,
    //   id: 15,
    // },
  ];
}

export const SELECT_ITEMS_LIST_REGION = [
  {
    id: 1,
    label: 'Österreich',
    isoCode: 'AT',
    img: 'assets/img/countries/austria.svg',
  },
  {
    id: 2,
    label: 'Deutschland',
    isoCode: 'DE',
    img: 'assets/img/countries/germany.svg',
  },
  {
    id: 4,
    label: 'Global',
    isoCode: 'other',
    img: 'assets/img/countries/globe.svg',
  },
];
