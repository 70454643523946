import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, finalize, ReplaySubject, takeUntil } from 'rxjs';

import { AppService } from './app.service';
import { LocalePrefixesEnum } from './locale/locale-prefixes.enum';
import { LocaleService } from './locale/locale.service';
import { TitleService } from './services/title.service';
import { CanonicalService } from './services/canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'runple-web';
  public isExternalPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hideRequestDemo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hideToTopButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public displayCookies$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isBrowser: boolean = false;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private appService: AppService,
    private localeService: LocaleService,
    private titleService: TitleService,
    private translate: TranslateService,
    private canonicalService: CanonicalService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    translate.setDefaultLang('en');

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      // @ts-ignore
      .subscribe((event: RouterEvent) => {
        { // set current locale prefix if it exists
          const urlArr = event.url.split('/').filter(segment => !!segment);
          if (Object.values(LocalePrefixesEnum).includes(urlArr[0] as LocalePrefixesEnum)) {
            this.localeService.setCurrentLocalePrefix(urlArr[0] as LocalePrefixesEnum);
          } else {
            // @ts-ignore
            this.localeService.setCurrentLocalePrefix(null);
          }
        }

        this.isExternalPage$.next(this.activeRoute?.firstChild?.snapshot?.data?.['isExternalPage'] || false);
        this.hideRequestDemo$.next(this.activeRoute?.firstChild?.snapshot?.data?.['hideRequestDemo'] || false);
        this.hideToTopButton$.next(this.activeRoute?.firstChild?.snapshot?.data?.['hideToTopButton'] || false);

        if (this.localeService.isLocaleAT) {
          translate.use('at');
          localStorage.setItem('lang', 'at');
          this.document.documentElement.lang = 'de-AT';
        } else if (this.localeService.isLocaleDE) {
          translate.use('de');
          localStorage.setItem('lang', 'de');
          this.document.documentElement.lang = 'de-DE';
        } else {
          translate.use('en');
          localStorage.setItem('lang', 'en');
          this.document.documentElement.lang = 'en';
        }
        this.titleService.updateMeta();

        if (isPlatformBrowser(this.platformId) && this.document) {
          this.displayCookies$.next(!localStorage.getItem('isCookiesAgree'));
        }
      });
  }

  ngOnInit() {
    this.titleService.boot();
    this.canonicalService.setCanonicalURL();

    if (isPlatformBrowser(this.platformId)) {
      this.appService.getUserInfo()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (!this.localeService.currentLocalePrefix) {
            if (data?.isoCode === 'DE' || data?.isoCode === 'AT') {
              this.localeService.changeLocale(data.isoCode.toLowerCase() as LocalePrefixesEnum);
            }
          }
          this.isLoading$.next(false);
        }, error => {
          this.isLoading$.next(false);
        });
    }

  }

  public policyAgreement(): void {
    this.displayCookies$.next(false)
    localStorage.setItem('isCookiesAgree', 'true');
  }

  public declinedCookies(): void {
    this.displayCookies$.next(false)
    localStorage.setItem('isCookiesAgree', 'false');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
