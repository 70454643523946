import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiesAgreementComponent } from './cookies-agreement/cookies-agreement.component';
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";



@NgModule({
  declarations: [
    CookiesAgreementComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
  ],
  exports: [
    CookiesAgreementComponent
  ]
})
export class CookiesAgreementModule { }
