import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  public showToaster$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }

  public showToaster(): void {
    this.showToaster$.next(true);
    setTimeout(() => {
      this.showToaster$.next(false)
    }, 3500);
  }
}
