import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';

import { getFeaturesList, getSolutionsList } from './header.config';
import { LocaleService } from '../../locale/locale.service';
import { RoutesEnum } from '../../locale/routes.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './styles/header.component.scss',
    './styles/header-navigation.scss',
    './styles/header-tablet.scss',
    './styles/header-mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {

  public featuresList: any[] = [];
  public solutionsList: any[] = [];
  public isOpenNav: boolean = false;
  public routesEnum: typeof RoutesEnum = RoutesEnum;
  public hideStartToFreeAction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,
    private activeRoute: ActivatedRoute,
    public localeService: LocaleService
  ) {
    this.hideStartToFreeAction$.next(this.activeRoute?.firstChild?.snapshot?.data?.['hideStartToFreeAction'] || false);
  }

  ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.hideStartToFreeAction$.next(this.activeRoute?.firstChild?.snapshot?.data?.['hideStartToFreeAction'] || false);
      });

    this.localeService.currentLocalePrefix$.subscribe(() => {
      this.getSolutionsNavList();
      this.getFeaturesNavList();
    });
  }


  public navToggle(): void {
    this.isOpenNav = !this.isOpenNav;
  }

  public closeNav(solutionGroup: HTMLDivElement, featuresGroup: HTMLDivElement): void {
    if (isPlatformBrowser(this.platformId)) {
      solutionGroup.removeAttribute('open');
      featuresGroup.removeAttribute('open');
      this.isOpenNav = false;
    }
  }

  trackByFn(index: number, item: any) {
    return item.title;
  }

  public getFeaturesNavList(): void {
    this.featuresList = getFeaturesList(
      this.localeService.getLink(RoutesEnum.TRADE),
      this.localeService.getLink(RoutesEnum.ECOMMERCE),
      this.localeService.getLink(RoutesEnum.SERVICES),
      this.localeService.getLink(RoutesEnum.RETAIL),
      this.localeService.getLink(RoutesEnum.ACCOUNTING),
      this.localeService.getLink(RoutesEnum.WAREHOUSE),
      this.localeService.getLink(RoutesEnum.ANALYTICS),
      this.localeService.getLink(RoutesEnum.PARTNERS),
      this.localeService.getLink(RoutesEnum.PRODUCTS),
      this.localeService.getLink(RoutesEnum.TEAM),
    )
  }

  public getSolutionsNavList(): void {
    this.solutionsList = getSolutionsList(
      this.localeService.getLink(RoutesEnum.INVENTORY_MANAGEMENT),
      this.localeService.getLink(RoutesEnum.ECOMMERCE_SOFTWARE),
      this.localeService.getLink(RoutesEnum.POINT_OF_SALE),
      this.localeService.getLink(RoutesEnum.ACCOUNTING_SOFTWARE),
    )
  }

  public goToSignUpPage(): void {
    this.router.navigate([this.localeService.getLink(RoutesEnum.START)]);
  }

  public goToSignIn(): void {
    this.router.navigate([this.localeService.getLink(RoutesEnum.SIGN_IN)]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
