import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

import { HeaderComponent } from './header.component';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
    imports: [
        CommonModule,
        RouterLink,
        NgOptimizedImage,
        TranslateModule
    ]
})
export class HeaderModule { }
