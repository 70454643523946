import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";

import { environment } from '../environments/environment';
import { LocaleService } from './locale/locale.service';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService, private localeService: LocaleService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('https:') && request.url.includes('s3')) {
      return next.handle(request);
    }

    let url: string;

    // weak checking (need to find better way)
    if (request.url.includes(environment.phpApiVersion)) {
      url = environment.phpApiUrl + request.url;
    } else if (request.url.includes(environment.javaApiVersion) || request.url.includes(environment.javaAccountingApiVersion)) {
      url = environment.javaApiUrl + request.url;
    } else {
      url = environment.apiUrl + request.url;
    }

    if (!request.url.includes('i18n')) {
      const headers: any = {
        Accept: 'application/json',
      };

      if (!request.url.includes(environment.javaApiVersion) && !request.url.includes(environment.javaAccountingApiVersion) && !request.url.includes(environment.phpApiVersion)) {
        const localStorageLang = localStorage.getItem('lang');
        const translateServerBrowserLang = this.translateService.getBrowserLang();
        const langByRequest = (translateServerBrowserLang === 'en' || translateServerBrowserLang === 'de' || translateServerBrowserLang === 'at') ? translateServerBrowserLang : 'en';
        const browserLang = localStorageLang ? localStorageLang : langByRequest;
        headers['language'] = browserLang === 'at' ? 'de' : browserLang;
        headers['localization'] = this.localeService.currentLocalePrefix || 'global';
      }


      request = request.clone({
        url,
        setHeaders: headers,
      });
    }

    return next.handle(request);
  }
}
