import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

import { ContactUsModalComponent } from './contact-us-modal.component';
import { FormValidIconModule } from '../../ui-components/form-valid-icon/form-valid-icon.module';


@NgModule({
  declarations: [
    ContactUsModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FormValidIconModule,
    TranslateModule,
  ],
})
export class ContactUsModalModule { }
