<div id="main-container" class="page-container" [class.has-header]="!(isExternalPage$ | async)">
  <ng-container *ngIf="!(isLoading$ | async)">
    <app-header *ngIf="!(isExternalPage$ | async)"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!(isExternalPage$ | async)"></app-footer>
    <app-request-demo *ngIf="!(isExternalPage$ | async) && !(hideRequestDemo$ | async)"></app-request-demo>
    <app-to-top-button [class.bottom-64]="hideRequestDemo$ | async" *ngIf="!(isExternalPage$ | async) && !(hideToTopButton$ | async)"></app-to-top-button>

    <app-cookies-agreement
      *ngIf="(displayCookies$ | async) && !(isExternalPage$ | async)"
      (isCookiesDecline)="declinedCookies()"
      (isCookiesAgree)="policyAgreement()">
    </app-cookies-agreement>
    <app-toaster></app-toaster>
  </ng-container>
</div>

