import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestDemoComponent implements OnInit, OnDestroy {
  public windowScrolled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private scrollListener = () => {
    if (isPlatformBrowser(this.platformId)) {
      this.windowScrolled.next(!!window.scrollY && window.scrollY > 300);
    }
  }

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.document) {
      window.addEventListener('scroll', this.scrollListener)
    }
  }

  async openModal() {
    const { RequestDemoModalComponent } = await import('../../modals/request-demo-modal/request-demo-modal.component');
    this.dialog.open(await RequestDemoModalComponent, {
      backdropClass: 'modal-backdrop',
      panelClass: 'modal-panel',
      disableClose: true,
      // scrollStrategy: new NoopScrollStrategy()
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollListener);
  }

}
