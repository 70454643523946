import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';

import { getBranchesList, getFeaturesList, getUsageScenariosList, SELECT_ITEMS_LIST_REGION } from './footer.config';
import { AvailableCountryISOEnum } from '../../models/language.enum';
import { LocalePrefixesEnum } from '../../locale/locale-prefixes.enum';
import { LocaleService } from '../../locale/locale.service';
import { RoutesEnum } from '../../locale/routes.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './styles/footer.component.scss',
    './styles/footer-navigation.scss',
    './styles/footer-desktop.scss',
    './styles/footer-tablet.scss',
    './styles/footer-mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {

  public branchesList: any[] = []
  public featuresList: any[] = [];
  public usageScenariosList: any[] = [];
  public selectItems = SELECT_ITEMS_LIST_REGION;
  public currentYear = new Date().getFullYear();
  public showAdditionalInfo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showAdditionalInfoCta$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public selectedRegion: any = SELECT_ITEMS_LIST_REGION[2];
  public routesEnum: typeof RoutesEnum = RoutesEnum;
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChildren('selectCountry') selectCountry: any = {};

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    public localeService: LocaleService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.showAdditionalInfo$.next(this.activatedRoute?.firstChild?.snapshot?.data?.['isLendingPage'] || false);
        this.showAdditionalInfoCta$.next(this.activatedRoute?.firstChild?.snapshot?.data?.['hasCta'] || false);
      });

    this.localeService.currentLocalePrefix$.subscribe((prefix: LocalePrefixesEnum) => {
      this.getBranchesNavList();
      this.getFeaturesNavList();
      this.usageScenariosList = getUsageScenariosList(
        this.localeService.getLink(RoutesEnum.ACCOUNTING_SEO),
        this.localeService.getLink(RoutesEnum.TRADE_SEO),
        this.localeService.getLink(RoutesEnum.ERP_SOFTWARE),
        this.localeService.getLink(RoutesEnum.ECOMMERCE_SEO),
        this.localeService.getLink(RoutesEnum.RUNPLE_FREE),
        this.localeService.getLink(RoutesEnum.ONLINE_STORE),
      )

      if (prefix) {
        // @ts-ignore
        this.setRegion(AvailableCountryISOEnum[prefix.toUpperCase()])
      } else {
        this.setRegion(AvailableCountryISOEnum.OTHER)
      }
    });
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  async contactusModal() {
    const { ContactUsModalComponent } = await import('../../modals/contact-us-modal/contact-us-modal.component');
    this.dialog.open(await ContactUsModalComponent, {
      backdropClass: 'modal-backdrop',
      panelClass: ['modal-panel', 'h-full'],
      disableClose: true
    });
  }
  async contactSupportModal() {
    const { ContactSupportModalComponent } = await import('../../modals/contact-support-modal/contact-support-modal.component');
    this.dialog.open(await ContactSupportModalComponent, {
      backdropClass: 'modal-backdrop',
      panelClass: ['modal-panel', 'h-full'],
      disableClose: true
    });
  }

  private setRegion(isoCode: AvailableCountryISOEnum): void {
    this.selectedRegion = {};
    const newRegion = this.selectItems.find((item) => item.isoCode === isoCode);
    if (newRegion) {
      this.selectedRegion = newRegion;
    }
  }

  public changeRegion(e: any): void {
    if (e && e.isoCode && (<any>Object).values(LocalePrefixesEnum).includes(e.isoCode.toLowerCase())) {
      this.localeService.changeLocale(e.isoCode.toLowerCase());
    } else {
      this.localeService.changeLocale();
    }
  }

  public getBranchesNavList(): void {
    this.branchesList = getBranchesList(
      this.localeService.getLink(RoutesEnum.POINT_OF_SALE),
      this.localeService.getLink(RoutesEnum.ECOMMERCE_SOFTWARE),
      this.localeService.getLink(RoutesEnum.INVENTORY_MANAGEMENT),
      this.localeService.getLink(RoutesEnum.ACCOUNTING_SOFTWARE),
    )
  }

  public getFeaturesNavList(): void {
    this.featuresList = getFeaturesList(
      this.localeService.getLink(RoutesEnum.RETAIL),
      this.localeService.getLink(RoutesEnum.ECOMMERCE),
      this.localeService.getLink(RoutesEnum.TRADE),
      this.localeService.getLink(RoutesEnum.SERVICES),
      this.localeService.getLink(RoutesEnum.ACCOUNTING),
      this.localeService.getLink(RoutesEnum.WAREHOUSE),
      this.localeService.getLink(RoutesEnum.ANALYTICS),
      this.localeService.getLink(RoutesEnum.PARTNERS),
      this.localeService.getLink(RoutesEnum.PRODUCTS),
      this.localeService.getLink(RoutesEnum.TEAM),
    )
  }

  public goToSignIn(): void {
    this.router.navigate([this.localeService.getLink(RoutesEnum.SIGN_IN)]);
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

}
