import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-to-top-button',
  templateUrl: './to-top-button.component.html',
  styleUrls: ['./to-top-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToTopButtonComponent implements OnInit, OnDestroy {

  public windowScrolled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private scrollListener = () => {
    if (isPlatformBrowser(this.platformId)) {
      this.windowScrolled.next(!!window.scrollY && window.scrollY > 300);
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.document) {
        window.addEventListener('scroll', this.scrollListener)
    }
  }

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId) && this.document) {
      window.scrollTo({top: 0});
    }
  }

  ngOnDestroy() {
    window?.removeEventListener('scroll', this.scrollListener);
  }
}
