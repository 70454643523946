import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";

import { ToasterComponent } from './toaster.component';


@NgModule({
  declarations: [
    ToasterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    ToasterComponent
  ]
})
export class ToasterModule { }
