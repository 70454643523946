import { Component } from '@angular/core';
import { ToasterService } from "./toaster.service";

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent {
  public message: string = 'MAIN.YOUR_REQUEST_HAS_BEEN_SENT';

  constructor(public toasterService: ToasterService) {
  }

}
