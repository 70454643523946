import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from "@angular/router";

import { RoutesEnum } from "../../../locale/routes.enum";
import { LocaleService } from "../../../locale/locale.service";

@Component({
  selector: 'app-cookies-agreement',
  templateUrl: './cookies-agreement.component.html',
  styleUrls: ['./cookies-agreement.component.scss']
})
export class CookiesAgreementComponent {

  public routesEnum: typeof RoutesEnum = RoutesEnum;

  @Output() public isCookiesAgree: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isCookiesDecline: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private router: Router,
    public localeService: LocaleService,
  ) {
  }

  public cookiesAgree(): void {
    this.isCookiesAgree.emit();
  }

  public cookiesDecline(): void {
    this.isCookiesDecline.emit();
  }

  public goToCookiesPage(): void {
    this.router.navigate([this.localeService.getLink(RoutesEnum.COOKIE_POLICY)]);
  }
}
