import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

import { FormValidIconModule } from '../../ui-components/form-valid-icon/form-valid-icon.module';
import { ContactSupportModalComponent } from "./contact-support-modal.component";

@NgModule({
  declarations: [
    ContactSupportModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FormValidIconModule,
    TranslateModule,
  ]
})
export class ContactSupportModalModule { }
