import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { RequestDemoComponent } from './request-demo.component';

@NgModule({
  declarations: [
    RequestDemoComponent
  ],
  exports: [
    RequestDemoComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    TranslateModule
  ]
})
export class RequestDemoModule { }
