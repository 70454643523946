<div class="privacy-agreement">

  <div class="icon-container">
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Path" d="M15.9947 27.6777C15.9091 27.6775 15.8269 27.7118 15.7667 27.7727C15.7065 27.8337 15.6733 27.9162 15.6746 28.0019C15.6753 28.1806 15.82 28.3254 15.9987 28.326C16.1145 28.3253 16.2212 28.2629 16.2785 28.1622C16.3357 28.0616 16.335 27.938 16.2765 27.8381C16.2179 27.7382 16.1105 27.677 15.9947 27.6777" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_2" d="M17.9955 17.6734C17.9098 17.6731 17.8277 17.7074 17.7675 17.7683C17.7073 17.8293 17.6741 17.9118 17.6753 17.9975C17.676 18.1762 17.8207 18.321 17.9995 18.3216C18.1785 18.3205 18.3227 18.1745 18.3216 17.9955C18.3205 17.8165 18.1745 17.6723 17.9955 17.6734" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_3" d="M25.9989 31.6792C25.9132 31.679 25.8311 31.7132 25.7709 31.7742C25.7107 31.8351 25.6775 31.9177 25.6788 32.0033C25.6794 32.1821 25.8242 32.3268 26.0029 32.3275C26.1819 32.3264 26.3261 32.1804 26.325 32.0013C26.3239 31.8223 26.1779 31.6781 25.9989 31.6792" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_4" d="M23.9979 23.6763C23.9123 23.6761 23.8301 23.7103 23.7699 23.7712C23.7097 23.8322 23.6765 23.9148 23.6778 24.0004C23.6785 24.1792 23.8232 24.3239 24.0019 24.3246C24.1177 24.3238 24.2243 24.2614 24.2816 24.1608C24.3389 24.0601 24.3381 23.9365 24.2796 23.8366C24.2211 23.7367 24.1137 23.6756 23.9979 23.6763" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path id="Path_5" fill-rule="evenodd" clip-rule="evenodd" d="M23.9999 5.99268C23.9999 15.9379 32.0622 24.0002 42.0074 24.0002C42.0074 33.9454 33.9452 42.0077 23.9999 42.0077C14.0547 42.0077 5.99243 33.9454 5.99243 24.0002C5.99243 14.0549 14.0547 5.99268 23.9999 5.99268Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  </div>

  <div class="privacy-agreement-content">
    <h4 class="title">{{ 'COOKIES.WE_USE_COOKIES' | translate }}</h4>
    <p class="descr">
      {{ 'COOKIES.COOKIES_DESCR' | translate }}
    </p>
  </div>

  <div class="buttons-container">
    <a [routerLink]="localeService.getLink(routesEnum.COOKIE_POLICY)" class="link descr">
      {{'MAIN.LEARN_MORE' | translate}}
    </a>

    <button class="btn btn-md btn-black" (click)="cookiesDecline()">
      <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.46967 6.46967C6.76256 6.17678 7.23744 6.17678 7.53033 6.46967L17.5303 16.4697C17.8232 16.7626 17.8232 17.2374 17.5303 17.5303C17.2374 17.8232 16.7626 17.8232 16.4697 17.5303L6.46967 7.53033C6.17678 7.23744 6.17678 6.76256 6.46967 6.46967Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5303 6.46967C17.8232 6.76256 17.8232 7.23744 17.5303 7.53033L7.53033 17.5303C7.23744 17.8232 6.76256 17.8232 6.46967 17.5303C6.17678 17.2374 6.17678 16.7626 6.46967 16.4697L16.4697 6.46967C16.7626 6.17678 17.2374 6.17678 17.5303 6.46967Z" fill="white"/>
      </svg>

      {{'MAIN.DECLINED' | translate}}
    </button>

    <button class="btn btn-md" (click)="cookiesAgree()">
      <img class="icon" height="24" width="24" src="assets/img/check.svg" [alt]="''">
      {{'MAIN.ACCEPT' | translate}}
    </button>
  </div>
</div>
