import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class TitleService {

  private lastEvent: any = null;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private translateService: TranslateService,
    private meta: Meta,
  ) {
    this.boot();

    this.translateService.onLangChange.subscribe(() => {
      this.updateMeta();
    });
  }

  boot() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.lastEvent = event;
        this.updateMeta(event);
      });
  }

  public updateMeta(event = this.lastEvent): void {
    if (event) {
      this.lastEvent = event;
      this.meta.updateTag({
        property: 'og:url',
        content: this.document.location.href
      });
      this.updateMetaTitle(event?.['metaTitle']);
      this.updateMetaDescription(event?.['metaDesc'], event?.['metaTitle']);

      if (this.isEnvironmentProduction) {
        if (event?.['noindex']) {
          this.meta.updateTag({
            name: 'robots',
            property: 'robots',
            content: 'noindex, nofollow'
          });
        } else {
          this.meta.updateTag({
            name: 'robots',
            property: 'robots',
            content: 'index, follow'
          });
        }
      } else {
        this.meta.updateTag({
          name: 'robots',
          property: 'robots',
          content: 'noindex, nofollow'
        });
      }
    }
  }

  public updateMetaTitle(title: string): void {
    const newTitle = title
      ? this.translateService.instant(title)
      : this.translateService.instant('');

    this.title.setTitle(newTitle);

    this.meta.updateTag({
      property: 'og:title',
      content: newTitle
    });

    this.meta.updateTag({
      name: 'title',
      content: newTitle
    });

    this.meta.updateTag({
      name: 'twitter:title',
      content: newTitle
    });
  }

  public updateMetaDescription(desc: string, title?: string): void {
    let newDesc = desc
      ? this.translateService.instant(desc)
      : this.translateService.instant('');

    this.meta.updateTag({
      property: 'og:description',
      content: newDesc
    });

    this.meta.updateTag({
      name: 'description',
      content: newDesc
    });

    this.meta.updateTag({
      name: 'twitter:description',
      content: newDesc
    });
  }

  public addMetaRobotsFollowTag(): void {
    this.meta.addTag({
      name: 'robots',
      property: 'robots',
      content: 'index, follow'
    });
  }

  public addMetaRobotsNoFollowTag(): void {
    this.meta.addTag({
      name: 'robots',
      property: 'robots',
      content: 'noindex, nofollow'
    });
  }

  get isEnvironmentProduction(): boolean {
    return environment.production;
  }
}
