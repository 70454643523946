import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

import { UserInfoResponse } from './models/user-Info.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public currentCountryIso: string = '';

  private readonly showWithCountryList: string[] = [
    'AT',
    // 'DE',
    // 'UA'
  ];

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public getUserInfo(): Observable<UserInfoResponse | null> {
    if (isPlatformServer(this.platformId)) {
      this.currentCountryIso = 'DE';
      return of(null);
    }
    return this.http.get<UserInfoResponse>('/get-info/ip')
      .pipe(
        tap((data) => {
          this.currentCountryIso = data.isoCode;
        })
      );
  }

  public showWithCountry(currentCountryIso: string): boolean {
    return this.showWithCountryList
      .some(countryIso => (countryIso && currentCountryIso) && countryIso.toLowerCase() === currentCountryIso.toLowerCase());
  }
}
